import { EnvironmentEnum } from '@sites/util-environment';

export const environment = {
  name: EnvironmentEnum.Development,
  production: false,
  // Replaced by actual value during build
  release: 'c205db93e1198b274b8929d2a1fc6897c6fb5992',

  dashboardUrl: 'https://dashboard-dev.humanmademachine.com',

  apiUrl: 'https://api-dev.humanmademachine.com',

  sentryDsn:
    'https://6504ab8504ec4403bbb00bfca4943b4c@o98546.ingest.sentry.io/6111916',
  sentrySampleRate: 1.0,
};
